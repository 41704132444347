<template>
  <div style="overflow: hidden">
    <div :class="['bg_wra', $route.params.name]">
      <h1>{{ title }}</h1>
      <h4 v-html="subtitle" class="padd-rig"></h4>
    </div>
    <div class="submenu">
      <a-anchor :offset-top="72" @click="handleClick" @change="onChange">
        <a-anchor-link
          href="#1001"
          title="业务挑战"
          :class="{ 'ant-anchor-link-active': isActive }"
        ></a-anchor-link>
        <a-anchor-link href="#1002" title="解决方案"></a-anchor-link>
        <a-anchor-link href="#1003" title="应用蓝图"></a-anchor-link>
        <a-anchor-link href="#1004" title="成功故事"></a-anchor-link>
      </a-anchor>
    </div>
    <div class="challenge" id="1001">
      <h1>业务挑战</h1>
      <a-row :gutter="16">
        <a-col :span="12" v-for="(item, index) in challenge" :key="index">
          <div class="half_part">
            <img :src="item.icon" alt="" />
            <div class="part_text">
              <h3>{{ item.text }}</h3>
              <h4>{{ item.subtext }}</h4>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="program" id="1002">
      <div :class="['program_con', $route.params.name]">
        <div class="program_wra">
          <h1>{{ title }}</h1>
          <a-row :gutter="8">
            <a-col :span="12" v-for="(item, index) in programs" :key="index">
              <div class="half-col">
                <div class="title">{{ item.title }}</div>
                <p>{{ item.desc }}</p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div class="blueprint" id="1003">
      <h1>应用蓝图</h1>
      <h4>{{ info }}</h4>
      <img src="../assets/image/solution/lantu.png" alt="" />
    </div>
    <div class="partner" id="1004">
      <div class="stories">
        <div class="stories_wra">
          <h1>客户成功故事</h1>
          <a-row :gutter="8">
            <a-col
              :span="12"
              v-for="(item, index) in stories[$route.params.name]"
              :key="index"
            >
              <div class="stories-col" @click="linkTo(item.link)">
                <img :src="item.src" alt="" />
                <p>{{ item.desc }}</p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import solutionData from './solutionData'
export default {
  name: 'SolutionDetails',
  data () {
    return {
      title: '',
      subtitle: '',
      challenge: '',
      programs: '',
      stories: {
        rencai: [
          {
            src: require('../assets/image/solution/chengying.png'),
            desc: '中国银行：加快住房租赁场景服务生态布局',
            link: 'https://mp.weixin.qq.com/s/RNdvYbzPkJ3Xu_DB5LVJAg'
          },
          {
            src: require('../assets/image/solution/zhongliang.png'),
            desc: '中粮集团：盘活存量、用好增量，高质量发展各项地产创新业务',
            link: 'https://mp.weixin.qq.com/s/glwxtguxIeBIkhVUWzanVQ'
          },
          {
            src: require('../assets/image/solution/zhengdong.png'),
            desc: '东龙控股：打造中部人才高地',
            link: 'https://mp.weixin.qq.com/s/xSPpHR6Vus3EmiQTmsx5nw'
          },
          {
            src: require('../assets/image/solution/xinyi.png'),
            desc: '新沂城投：充分发挥人才公寓在集聚人才方面的积极作用',
            link: 'https://mp.weixin.qq.com/s/46xEE8XHDdydD7tJNLWJZw'
          }
        ],
        shangye: [
          {
            src: require('../assets/image/solution/zhonghai.png'),
            desc: '中海地产：打造长租公寓资产管理全链条能力',
            link: 'https://mp.weixin.qq.com/s/k9HhbGTvfiMnLrksc6GI2Q'
          },
          {
            src: require('../assets/image/solution/zhongliang.png'),
            desc: '中粮集团：盘活存量、用好增量，高质量发展各项地产创新业务',
            link: 'https://mp.weixin.qq.com/s/glwxtguxIeBIkhVUWzanVQ'
          },
          {
            src: require('../assets/image/solution/hejing.png'),
            desc: '合景泰富：让这里真正成为有温度有故事的“家”',
            link: 'https://mp.weixin.qq.com/s/3SR7z18EV2l7keu43cOzrw'
          },
          {
            src: require('../assets/image/solution/jindi.png'),
            desc: '金地集团：做最有温度的有爱社区',
            link: ''
          }
        ],
        dichan: [
          {
            src: require('../assets/image/solution/zhonghai.png'),
            desc: '中海地产：打造长租公寓资产管理全链条能力',
            link: 'https://mp.weixin.qq.com/s/k9HhbGTvfiMnLrksc6GI2Q'
          },
          {
            src: require('../assets/image/solution/zhongliang.png'),
            desc: '中粮集团：盘活存量、用好增量，高质量发展各项地产创新业务',
            link: 'https://mp.weixin.qq.com/s/glwxtguxIeBIkhVUWzanVQ'
          },
          {
            src: require('../assets/image/solution/hejing.png'),
            desc: '合景泰富：让这里真正成为有温度有故事的“家”',
            link: 'https://mp.weixin.qq.com/s/3SR7z18EV2l7keu43cOzrw'
          },
          {
            src: require('../assets/image/solution/jindi.png'),
            desc: '金地集团：做最有温度的有爱社区',
            link: ''
          }
        ],
        yinhang: [
          {
            src: require('../assets/image/solution/chengying.png'),
            desc: '中国银行：加快住房租赁场景服务生态布局',
            link: 'https://mp.weixin.qq.com/s/RNdvYbzPkJ3Xu_DB5LVJAg'
          }
        ],
        xitong: [
          {
            src: require('../assets/image/solution/zhonghai.png'),
            desc: '中海地产：打造长租公寓资产管理全链条能力',
            link: 'https://mp.weixin.qq.com/s/k9HhbGTvfiMnLrksc6GI2Q'
          },
          {
            src: require('../assets/image/solution/zhongliang.png'),
            desc: '中粮集团：盘活存量、用好增量，高质量发展各项地产创新业务',
            link: 'https://mp.weixin.qq.com/s/glwxtguxIeBIkhVUWzanVQ'
          }
        ]
      },
      titleList: {
        dichan: '保障性租赁住房管理系统-寓信',
        rencai: '人才公寓管理系统-寓信',
        shangye: '地产公寓管理系统-寓信',
        xitong: '企业园区宿舍管理系统-寓信'
      },
      info: '',
      isActive: true
    }
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.initData()
      }
    }
  },

  methods: {
    initData () {
      this.title = solutionData[this.$route.params.name].title
      this.subtitle = solutionData[this.$route.params.name].subtitle
      this.challenge = solutionData[this.$route.params.name].challenge
      this.programs = solutionData[this.$route.params.name].programs
      this.info = solutionData[this.$route.params.name].info
      document.title = this.titleList[this.$route.params.name]
    },
    handleClick (e, link) {
      e.preventDefault()
      console.log(link)
    },
    onChange (link) {
      console.log(link)
      if (link && link != '#1001') {
        this.isActive = false
      } else {
        this.isActive = true
      }
    },
    linkTo (url) {
      url && window.open(url)
    }
  },
  created () {
    this.title = solutionData[this.$route.params.name].title
    this.subtitle = solutionData[this.$route.params.name].subtitle
    this.challenge = solutionData[this.$route.params.name].challenge
    this.programs = solutionData[this.$route.params.name].programs
    this.info = solutionData[this.$route.params.name].info
  }
}
</script>

<style lang="less" scoped>
h1,
h3,
h4 {
  color: #41505f;
}
.bg_wra {
  height: 480px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &.rencai {
    background-image: url('../assets/image/solution/rencai_bg.png');
  }
  &.shangye {
    background-image: url('../assets/image/solution/shangye_bg.png');
  }
  &.dichan {
    background-image: url('../assets/image/solution/gongzu_bg.png');
  }
  &.yinhang {
    background-image: url('../assets/image/solution/lanling_bg.png');
  }
  &.xitong {
    background-image: url('../assets/image/solution/xitong_bg.png');
    h1,
    h4 {
      color: #ffffff;
    }
  }
  h1 {
    width: 1200px;
    margin: 0 auto;
    padding-top: 170px;
  }
  h4 {
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 20px;
  }
  .padd-rig {
    padding-right: 450px;
  }
}
.submenu {
  /deep/.ant-anchor {
    width: 1200px;
    margin: 0 auto;
  }
  /deep/.ant-anchor-ink {
    display: none;
  }
  /deep/.ant-anchor-link {
    display: inline-block;
    color: #41505f;
    font-size: 15px;
    padding: 0;
    margin-right: 60px;
    line-height: 60px;
  }
  /deep/.ant-anchor-link-title {
    color: #41505f;
    border-bottom: solid 3px transparent;
  }
  /deep/.ant-anchor-link-active > .ant-anchor-link-title {
    border-bottom: solid 3px #0085f1;
    color: #41505f;
  }
}
.challenge {
  width: 1200px;
  margin: 0 auto;
  padding-top: 72px;
  h1 {
    text-align: center;
    color: #41505f;
    font-size: 30px;
    margin-bottom: 50px;
  }
  .half_part {
    padding: 60px 30px 0;
    height: 200px;
    background: #f7f7f7;
    margin-bottom: 16px;
    overflow: hidden;
    img {
      width: 60px;
      height: 60px;
      float: left;
    }
    .part_text {
      float: left;
      margin-left: 20px;
      h3 {
        font-size: 22px;
      }
      h4 {
        width: 383px;
      }
    }
  }
}
.program {
  padding-top: 72px;
  .program_con {
    height: 860px;
    padding: 68px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.rencai {
      background-image: url('../assets/image/solution/rencai_bg2.png');
    }
    &.shangye {
      background-image: url('../assets/image/solution/shangye_bg2.png');
    }
    &.dichan {
      background-image: url('../assets/image/solution/gongzu_bg2.png');
    }
    &.yinhang {
      background-image: url('../assets/image/solution/lanling_bg2.png');
    }
    &.xitong {
      background-image: url('../assets/image/solution/lanling_bg2.png');
    }
    .program_wra {
      width: 1200px;
      margin: 0 auto;
      h1 {
        text-align: center;
        color: #fff;
        margin-bottom: 60px;
      }
      .half-col {
        height: 259px;
        padding: 60px 36px 0;
        background-color: rgba(36, 132, 237, 0.2);
        color: #fff;
        margin-bottom: 8px;
        &:hover {
          background-color: #2484ed;
        }
        .title {
          height: 80px;
          font-size: 22px;
          position: relative;
          &:before {
            content: '';
            display: block;
            width: 30px;
            height: 4px;
            background-color: #08bdfa;
            position: absolute;
            top: 50px;
            left: 0;
          }
        }
      }
    }
  }
}
.blueprint {
  width: 1200px;
  margin: 0 auto;
  padding-top: 72px;
  h1 {
    text-align: center;
  }
  h4 {
    text-align: center;
    padding: 0 50px;
  }
  img {
    margin-top: 50px;
    width: 100%;
  }
}
.partner {
  padding-top: 72px;
  .stories {
    background-color: #eaeff3;
    padding: 72px 0;
    .stories_wra {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      .stories-col {
        cursor: pointer;
        &:hover {
          box-shadow: 0px 5px 10px 0px rgba(18, 32, 46, 0.4);
        }
      }
      h1 {
        margin-bottom: 40px;
      }
      img {
        width: 100%;
        height: 256px;
      }
      p {
        font-size: 20px;
        line-height: 100px;
        background-color: #fff;
        margin-bottom: 10px;
        &:hover {
          box-shadow: 0px 7px 12px 4px rgba(225, 225, 225, 0.8);
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .bg_wra {
    height: 200px;
    padding: 0 10px;
    h1 {
      padding-top: 80px;
      width: 100%;
      font-size: 18px;
    }
    h4 {
      width: 100%;
      font-size: 12px;
      padding-top: 10px;
    }
    .padd-rig {
      padding-right: 70px;
    }
  }
  .submenu {
    display: none;
  }

  .challenge {
    width: 100%;
    padding-top: 30px;
    h1 {
      margin-bottom: 30px;
    }
    .half_part {
      height: 240px;
      padding: 20px 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .part_text {
        width: 100%;
        margin-left: 0;
        text-align: center;
        h3 {
          width: 100%;
          font-size: 18px;
        }
        h4 {
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }
  .program {
    padding-top: 32px;
    .program_con {
      height: auto;
      .program_wra {
        width: 100%;
        h1 {
          margin-bottom: 30px;
          font-size: 26px;
        }
        .half-col {
          padding: 20px 10px 0;
          font-size: 12px;
          height: 300px;
          .title {
            font-size: 18px;
          }
        }
      }
    }
  }
  .blueprint {
    width: 100%;
    padding-top: 32px;
    h4 {
      padding: 0 20px;
      font-size: 12px;
    }
  }
  .partner {
    padding-top: 32px;
    .stories {
      height: auto;
      padding: 32px 0;
      .stories_wra {
        width: 100%;
        img {
          height: 88px;
        }
        p {
          font-size: 12px;
          height: 100px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          line-height: inherit;
        }
      }
    }
  }
}
</style>
