export default {
  rencai:{
    title:'政府人才公寓数字化解决方案',
    subtitle:'提供1个申报平台+1个物业服务平台+1个人才服务端+N个第三方开放业务场景，服务人才从“申请、入住、租后、退租”的全周期数字化管理。',
    challenge:[{
      icon:require('../assets/image/solution/rencai_icon1.png'),
      text:'人才申报流程传统复杂',
      subtext:' 通过智能、信息化的综合申报平台，提高人才的审批效率。',
    },{
      icon:require('../assets/image/solution/rencai_icon2.png'),
      text:'房源信息分配数据不能实时在线',
      subtext:'提高公寓租住管理效率和服务品质，解决分配难、管理难等问题。',
    },{
      icon:require('../assets/image/solution/rencai_icon3.png'),
      text:'租后服务“安全”不可控',
      subtext:'通过设立智能化设备生态系统，全方位实施监测安全隐患。',
    },{
      icon:require('../assets/image/solution/rencai_icon4.png'),
      text:'信息时代，大数据库被遗忘',
      subtext:'使用大数据、云计算等科技化手段，合理分析人才群体，提升综合体的整体竞争力。',
    }],
    programs:[{
      title:'网上人才申报评审系统',
      desc:'通过系统实现线上申报，线上审批的整个过程，为政府实现重点吸引人才、管理人才、成就人才的业务。'
    },{
      title:'房源智能分配管理系统',
      desc:'通过人才智慧房源分配系统查看整体房屋分配签约情况、层层点击了解详细概况，最终将所有的人才房源租住统计信息均通过系统来落实，建立人才住房租赁基础信息库。'
    },{
      title:'租住服务安全管理系统',
      desc:'有效解决入住公寓人员的数据化、智能化、常态化管理，同时可以动态掌握人才入住率，排查陌生访客，通过全生态物联网技术最大限度保障入住人才安全。'
    },{
      title:'BI大数据人才统计分析系统',
      desc:'通过对基础信息、业务信息、行为信息等进行分析，统计分析内容可定期选择性的导出查看，便于管理部门和领导决策者对各地区、各行业、各领域、各产业的人才工作情况全面掌握。'
    }],
    info:'围绕人才公寓行业数字化、创新化、生态化发展，寓信携手百家生态合作伙伴，构建智慧的管理场景和数字化的信息平台，打通租赁行业运营端、服务端、物联网端的租赁产业互联平台，并利用寓信全方位企业管理服务赋能公寓企业，实现企业员工在线、客户在线、服务在线、管理在线，助力企业管理者提高运营效率，丰富服务内容，提升服务质量，创造更多商业蓝图。'
  },
  shangye:{
    title:'地产品牌公寓数字化解决方案',
    subtitle:'基于PMS系统提供整套资产管理数字化解决方案，<br/>赋能公寓经营者整盘分析四大支持：收益、增长、流失、风险，布阵点兵，提升出租率和收益。',
    challenge:[{
      icon:require('../assets/image/solution/shangye_icon1.png'),
      text:'品牌效应薄弱，获客成本高',
      subtext:'专属定制租客APP、官网、微信小程序等打造自有线上拓客渠道，实现全方面多维度获客。'
    },{
      icon:require('../assets/image/solution/shangye_icon2.png'),
      text:'集团内部系统管控流程无法嵌入',
      subtext:'集团管控，内部审批流程，财务网管系统，租后工单系统全面对接打通，无缝融入集团体系，一体化管理更轻松。'
    },{
      icon:require('../assets/image/solution/shangye_icon3.png'),
      text:'收益管理能力粗糙，精细化颗粒度不足',
      subtext:'因配租家庭收入偏低、催缴租金找人难。租户欠缴租金，追缴困难、水电费上门抄表，耗费人力物力。'
    },{
      icon:require('../assets/image/solution/shangye_icon4.png'),
      text:'安全服务不可控，资产统计不及时',
      subtext:'实时监测门锁、水表、电表，远程操控、预警异常情况，减少能源浪费，提升项目运行可靠性，从资产的采购入库到报废全周期追踪，有迹可循。'
    }],
    programs:[{
      title:'智慧管理',
      desc:'工作全流程在线化、资产管理数据化、运营管理平台化'
    },{
      title:'电子签约',
      desc:'通过实名认证、云端证书、公证保全措施保障合同签署合法有效。'
    },{
      title:'万物互联',
      desc:'通过系统将门锁、水电表等智能硬件集成，在房屋管理环节联动控制。 '
    },{
      title:'品牌店铺',
      desc:'配备公寓运营商品牌官网、微信小程序， 实现线上线下无缝对接，闭环业务流程。'
    }],
    info:'围绕商业化长租公寓企业数字化、创新化、生态化发展，寓信携手百家合作伙伴，构建智慧的管理场景和数字化的信息基础平台，打通租赁行业运营端、服务端、物联网端的租赁产业互联平台，并利用寓信全方位企业管理服务赋能公寓企业，助力企业管理者提高运营效率，丰富服务内容，提升服务质量，创造更多商业蓝图。'
  },
  dichan:{
    title:'保障性租赁住房数字化解决方案',
    subtitle:'构建一站式、一体化、高质量、高效率的保障性租赁住房管理平台，提升政府的监督管理水平以及住房租赁市场的服务质量。',
    challenge:[{
      icon:require('../assets/image/solution/gongzu_icon1.png'),
      text:'监督市场主体不清晰',
      subtext:'通过数字化系统建设，实现住房租赁企业向主管部门进行开业报告，配合相关政策约束，高效支撑对住房租赁市场主体的监督。'
    },{
      icon:require('../assets/image/solution/gongzu_icon2.png'),
      text:'项目管理不高效',
      subtext:'通过数字化系统建设，实现城市规划和建设局及执委会其他相关部门的联合审批。同时，实现对执委会投资保障性租赁住房项目的管理、运营及分配，高效赋能保障性租赁住房的项目管理。'
    },{
      icon:require('../assets/image/solution/gongzu_icon3.png'),
      text:'惠民政策落地缓慢',
      subtext:'保障性租赁住房是住房保障体系的重要内容，对符合条件的申请人实行租金打折制度，租金优惠力度较大。通过数字化系统的建设，实现政策高效宣传、房源线上浏览和房源在线申请，保障惠民惠企政策有效落地。'
    },{
      icon:require('../assets/image/solution/gongzu_icon4.png'),
      text:'管理决策没有数据',
      subtext:'通过数字化系统的建设，可实现对保障性租赁住房的筹建渠道监测分析、租金监测分析、入住率监测分析、居民需求监测分析、满意度监测分析等，高效支撑领导决策。'
    }],
    programs:[{
      title:'运营服务管理系统',
      desc:'经营管理8大模块闭环，系统支撑近百家门店稳定运行3年以上管理数十万套房源，每月持续迭代重量级功能，构建企业运营的云端智慧大脑，实现智慧化运营管理。'
    },{
      title:'成本管控系统',
      desc:'三级费项五大科目，颗粒度到天，精细化管理，费控系统按季度预警，智能设备加持，异常流程预警，节水节电，行业成本管控NO.1。'
    },{
      title:'智能设备管控系统',
      desc:'IOT智能设备，RBA（ 远程楼宇控制系统）人脸识别门禁、 消防安保预警，24h无死角覆盖；使用各类智能设备实时监测门锁、水表、电表，远程操控、预警异常情况，提升项目运行可靠性。RBA系统可替代人工巡检，降低运营成本，从传感器到手机端，全程自动化运行。'
    },{
      title:'租后服务分析系统',
      desc:'从资产的采购入库到报废全周期追踪，有迹可循；每半年度资产盘点，减少资产损失；电子化物业交割，清晰可追溯。'
    }],
    info:'寓信科技凭借领先的平台技术，探索“互联网+租房”的新举措，重新连接人与空间，智造安全、便捷的长租公寓管理体验。整合房地产集团各级管理需求，完成从入住到退房的全流程监管，做到住户管理、房源管理、物业服务、社区服务在一个平台上实现，提高管理效率和服务品质，解决住房租赁管理难度大，服务不及时等问题。'
  },
  yinhang:{
    title:'银行企业数字化解决方案',
    subtitle:'响应国家号召、落实国家政策、助力住房租赁企业发展、管控资金监管\n',
    challenge:[{
      icon:require('../assets/image/solution/lanling_icon1.png'),
      text:'缺乏“住房租赁”科技平台实践经验',
      subtext:'做saas平台逻辑复杂、人力成本高、耗费时间长。'
    },{
      icon:require('../assets/image/solution/lanling_icon2.png'),
      text:'资源匹配不及时，方案落地慢',
      subtext:'行业老兵成熟业务助力平台快速上线、多软硬件快速实施对接主力数字化转型。'
    }],
    programs:[{
      title:'全方位科技赋能住房租赁行业',
      desc:'银行数字化解决方案成熟、紧跟时政、深度研究国家发布最新相关政策，做最能落地的解决方案'
    },{
      title:'成熟的SaaS解决方案\n',
      desc:'行业经验丰富，对业务理解深刻，深谙平台类产品的落地之法，成熟业务助力平台快速上线、多软硬件快速实施对接主力数字化转型'
    },{
      title:'安全、可扩展',
      desc:'金融、财务报表类型、接口丰富，扩展性强，提升业财一体化建设效率'
    },{
      title:'BI数据统计展示',
      desc:'筛选业务开展落地时的关键数据，按业务场景进行有效区分，重要数据大屏展示并可根据业务需要设置阈值，实时了解项目运营情况'
    }],
    info:'寓信科技与银行企业在住房租赁行业数字化合作方案成熟，且紧跟时政，深度研究国家发布最新相关政策，只做高效落地的解决方案。'
  },
  xitong:{
    title:'企业园区宿舍系统集成解决方案',
    subtitle:'通过数据整合与信息平台建设，实现各子系统的完美协调，打造智慧化管理与高效运行的企业园区宿舍，提高服务的明确性、高效性、灵活性和响应速度。',
    challenge:[{
      icon:require('../assets/image/solution/lanling_icon1.png'),
      text:'租赁情况难掌握',
      subtext:'建立统一的管理平台，推动新技术与园区的结合，提升管理效能、降低运营成本。'
    },{
      icon:require('../assets/image/solution/lanling_icon2.png'),
      text:'企业服务难落地',
      subtext:'构建全方位园区企业服务体系，加强各主体间深度链接，搭建更开放的环境。'
    },{
      icon:require('../assets/image/solution/rencai_icon1.png'),
      text:'园区业态复杂',
      subtext:'多场景软硬件结合，打造园区管理调度平台。'
    },{
      icon:require('../assets/image/solution/rencai_icon4.png'),
      text:'缺乏可视化展示',
      subtext:'基于可视化的数据大屏进行直观的数据收集、整合、分析。'
    }],
    programs:[{
      title:'全方位科技赋能住房租赁行业',
      desc:'企业园区宿舍解决方案成熟、紧跟时政、深度研究国家发布最新相关政策，做最能落地的解决方案'
    },{
      title:'成熟的SaaS解决方案\n',
      desc:'行业经验丰富，对业务理解深刻，深谙平台类产品的落地之法，成熟业务助力平台快速上线、多软硬件快速实施对接主力数字化转型'
    },{
      title:'安全、可扩展',
      desc:'金融、财务报表类型、接口丰富，扩展性强，提升业财一体化建设效率'
    },{
      title:'BI数据统计展示',
      desc:'筛选业务开展落地时的关键数据，按业务场景进行有效区分，重要数据大屏展示并可根据业务需要设置阈值，实时了解项目运营情况'
    }],
    info:'寓信科技与银行企业在住房租赁行业数字化合作方案成熟，且紧跟时政，深度研究国家发布最新相关政策，只做高效落地的解决方案。'
  }
}